.px-invoice-details {
  .address-container {
    display: flex;
    justify-content: space-between;
    width: 25%;
    .inline {
      display: inline;
    }
  }
  .address-container span {
    display: block;
    margin: 0.2rem 0 0 0;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .bold {
    font-weight: bold;
  }
  .invoice-info {
    width: 40%;
    margin: 1.2rem 0 1.2rem 0;
    .detail {
      padding: 0.8rem 0 0.8rem 0;
    }
  }
  .invoice-line-items {
    width: 55%;
    min-width: 500px;
    .icon {
      padding: 0;
      margin: 0 0 10px 10px;
      bottom: 5px;
    }
  }
  .invoice-subtotal {
    width: 75%;
  }
  .order-metrics {
    width: 42%;
    float: right;
    position: relative;
    margin: 1.2rem 0 1.2rem 0;
    .detail {
      display: flex;
      width: 40%;
      justify-content: space-between;
      padding: 0.8rem 0 0.8rem 0;
    }
    .icon-tax {
      position: absolute;
      padding: 0%;
      margin: 0 0 10px 10px;
      // bottom: 5px;
    }
  }
  .invoice-header {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
}
.invoice-dialog .MuiPaper-root.MuiDialog-paper {
  min-width: 30vw !important;
  padding: 1rem 0 1rem 1rem;
}

.invoice-dialog .content {
  margin: 10px;
  p {
    margin-bottom: 0;
  }
}
.px-background-black {
  background-color: RGB(0, 0, 0);
}
