.px-update-config {
  position: relative;
  .action-button {
    position: absolute;
    right: 0;
  }
  .tenant-select {
    width: 30%;
    min-width: 200px;
  }
}
