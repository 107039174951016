header {
  height: 50px;
}

.px-c-TopMenu {
  --px_user_data_color: 255, 255, 255;
  display: block;
  width: 100%;
  height: 50px;
  text-align: right;
  position: absolute;
  z-index: 1299;
  padding: 0 20px;
  border-bottom: 1px solid RGB(var(--px_color_border));
  background-color: RGB(var(--px_color_background));
  ul {
    margin: 0 0 0 -20px;
  }
  ul,
  li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 44px;
  }
  ul li a {
    display: inline-block;
    line-height: 44px;
    padding: 0 10px;
    text-transform: uppercase;
    color: RGB(var(--px_color_link));
  }
  ul li a:active {
    color: RGB(var(--px_color_link_active));
  }
  ul li a:visited {
    color: RGB(var(--px_color_link_visited));
  }
  ul li a:hover {
    color: RGB(var(--px_color_link_hover));
  }

  .px-user-initials {
    font-size: 12px;
    background-color: RGB(var(--px_user_data_color));
    margin-top: 4px;
    &.MuiIconButton-colorPrimary:hover {
      background-color: RGBA(var(--px_user_data_color), 1);
    }
    .MuiIconButton-label {
      color: RGB(var(--px_color_text_on_badge));
    }
  }
}
