.dashboard {

    .cardx {
        display: inline-block;
        width: 340px;
        min-height: 100px;
        margin: 20px;
        padding: 20px;
        box-shadow: 2px 2px 6px 3px rgba(0,0,0,0.21);
        -webkit-box-shadow: 2px 2px 6px 3px rgba(0,0,0,0.21);
        -moz-box-shadow: 2px 2px 6px 3px rgba(0,0,0,0.21);
    }
}
