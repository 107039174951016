* {
  box-sizing: border-box;
}

html {
  // min-height: 100%;
  position: relative;
  font-size: 14px;
}

body {
  background-color: RGB(var(--px_color_background));
  color: RGB(var(--px_color_text_primary));
  margin: 0;
  // font-size: 13px;
}

a,
a svg {
  color: RGB(var(--px_color_link));
  text-decoration: none;
}

a:active {
  color: RGB(var(--px_color_link_active));
}

a:visited {
  color: RGB(var(--px_color_link_visited));
}

a:hover,
a:hover svg {
  color: RGB(var(--px_color_link_holver));
}

main {
  padding: 20px;
}

small {
  font-size: 0.84rem;
}

.block {
  display: block;
}
