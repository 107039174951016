.px-clients {
  .px-grid-chips {
    padding: 0.4rem 0.7rem;
    font-size: 12px;
    border-radius: 16px;
    color: RGB(var(--px_color_text_on_badge));
    line-height: 40px !important;
  }
  .px-grid-chips:hover {
    cursor: pointer;
  }
  .px-background-primary {
    background-color: RGB(var(--px_color_primary));
  }

  .px-background-secondary {
    background-color: RGB(var(--px_color_secondary));
  }

  .px-background-warning {
    background-color: RGB(var(--px_color_warning));
  }

  .px-background-error {
    background-color: RGB(var(--px_color_error));
  }

  .px-background-badge_1 {
    background-color: RGB(var(--px_color_badge_1));
  }

  .px-background-badge_1b {
    background-color: RGB(var(--px_color_badge_1b));
  }

  .px-background-badge_2 {
    background-color: RGB(var(--px_color_badge_2));
  }

  .px-background-badge_2b {
    background-color: RGB(var(--px_color_badge_2b));
  }

  .px-background-badge_3 {
    background-color: RGB(var(--px_color_badge_3));
  }

  .px-background-badge_3b {
    background-color: RGB(var(--px_color_badge_3b));
  }

  .px-background-badge_4 {
    background-color: RGB(var(--px_color_badge_4));
  }

  .px-background-badge_5 {
    background-color: RGB(var(--px_color_badge_5));
  }

  .px-background-badge_6 {
    background-color: RGB(var(--px_color_badge_6));
  }
  .px-background-purple{
    background-color: #93f;
  }
  .client-header{
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: center;
  }
  .export-button{
    height: 40px;
  }
}
