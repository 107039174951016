.px-internal-user-management {
  .ag-theme-alpine .ag-header-viewport .ag-header-container .ag-header-row .ag-header-cell {
    padding-left: 12px !important;
  }

  .ag-cell .ag-cell-wrapper .ag-selection-checkbox.ag-hidden {
    visibility: visible !important;
    display: flex !important;
    opacity: 0.3;
    pointer-events: none;
  }
}

#info-tooltip,
#info-tooltip * {
  background-color: RGB(var(--px_color_background));
  color: RGB(var(--px_color_text_secondary));
  transition: none;
}

div#info-tooltip[role='tooltip'] {
  background-color: RGB(var(--px_color_background));
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
}
