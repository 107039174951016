.px-invoice {
  .ag-header-row .ag-header-cell[col-id='invoiceNumber'].px-header-invoiceNumber {
    padding-left: 2px;
  }
  .px-background-black{
    background-color: RGB(0,0,0);
  }
}
.invoice-dialog-content-warpper {
  width: 25vw;
  min-width: 500px;
  .invoice-dialog-content {
    display: flex;
    justify-content: space-between;
    width: 75%;
  }
  @media only screen and (max-width: 786px) {
    min-width: 400px;
  }
}
