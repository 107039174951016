.px-datefield-container {
  display: flex;
  flex-direction: column;
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -16px;
  }

  // .MuiPickersDay-daySelected,
  // .MuiPickersDay-daySelected .MuiIconButton-label .MuiTypography-root {
  //   color: var(--px_color_primary) !important;
  // }
}

.px-date-dialog .MuiPickersBasePicker-container .MuiButton-root {
  color: var(--px_color_text_on_primary);
}
