.px-invoices {
  .invoice-header{
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: center;
  }
  .export-button{
    height: 40px;
  }
}
