.px-new-tenant {
  section,
  .border-top {
    border-top: 1px solid #ccc;
    margin-top: 30px;
    padding-top: 30px;
  }

  .textField {
    width: 400px;
  }

  .MuiStepper-root {
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  .MuiStepIcon-root.MuiStepIcon-completed,
  .completed {
    color: #17bb17 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #ccc;
  }

  .block {
    display: block;
  }

  .error {
    color: red;
  }

  .px-workflow-status span {
    line-height: 30px;
    span {
      font-weight: bolder;
    }
  }

  .px-workflow-table thead tr {
    background-color: #ddd;
  }

  .px-workflow-table td {
    border: 1px solid #aaa;
    line-height: 30px;
    padding: 0 20px;
  }
}
