.px-update-config {
  position: relative;
  .action-button {
    position: absolute;
    right: 0;
  }
  .tenant-select {
    width: 30%;
    min-width: 200px;
    .text-field {
      width: 100%;
    }
  }
  .image-preview {
    width: 250px;
    margin: 1rem 0rem;
    img {
      width: 100%;
    }
  }
  .upload-button {
    width: 20%;
    min-width: 150px;
    margin-bottom: 1rem;
    label {
      width: 100%;
    }
  }
  .config-wrapper {
    .MuiAccordionSummary-content {
      justify-content: space-between;
      .error{
        color: RGB(var(--px_color_warning));
      }
      .success{
        color: RGB(var(--px_color_success));
      }
    }
  }
}
