@import 'styles/variables';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'foundation-sites/scss/foundation';

$header-styles: (
  'small': (
    'h1': (
      'font-size': 2.6rem,
    ),
    'h2': (
      'font-size': 2.3rem,
    ),
    'h3': (
      'font-size': 1.8rem,
    ),
    'h4': (
      'font-size': 1.4rem,
    ),
    'h5': (
      'font-size': 1.2rem,
    ),
    'h6': (
      'font-size': 1rem,
    ),
  ),
  'medium': (
    'h1': (
      'font-size': 2.6rem,
    ),
    'h2': (
      'font-size': 2.3rem,
    ),
    'h3': (
      'font-size': 1.8rem,
    ),
    'h4': (
      'font-size': 1.4rem,
    ),
    'h5': (
      'font-size': 1.2rem,
    ),
    'h6': (
      'font-size': 1rem,
    ),
  ),
);

// Global styles
// @include foundation-global-styles;
// @include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
// @include foundation-button;
// @include foundation-button-group;
// @include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
// @include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
// @include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
// @include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

@import 'styles/elements';
@import 'styles/helper';
@import 'styles/customization';
@import 'styles/animation';

.faded {
  color: #ccc;
}
.uppercase {
  text-transform: uppercase;
}
.MuiAlert-standardError {
  background-color: red !important;
}
