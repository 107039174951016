.px-custom-date-range-filter-selector-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  .px-datefield-container {
    width: 48%;
    min-width: 200px;
    margin-bottom: 16px;
  }
}
