@import 'utilities';

$spaceamounts: (2,
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@include generate_padding_margins($spaceamounts, $sides);

html {
  scroll-behavior: smooth;
  scrollbar-width: 0px;
}

html::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

html.scrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  display: block !important;
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

h2 {
  font-size: 60px;
  font-weight: 300;
  color: RGB(var(--px_color_text_primary));
}

h3 {
  font-size: 40px;
  font-weight: 300;
  color: RGB(var(--px_color_text_primary));
}

.px-grid-loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.px-circular-loader {
  border: 8px solid RGBA(var(--px_color_primary), var(--px_alpha_lighter));
  border-top: 8px solid RGB(var(--px_color_primary));
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 2s linear infinite;
  color: RGB(var(--px_color_primary));
}

.px-full-width-hr {
  margin: 0;
  max-width: 100%;
}

.px-bold-fonts {
  font-weight: 600;
  font-size: 14px;
}

.border-top {
  border-top: 1px solid RGB(var(--px_color_border));
}

.border-bottom {
  border-bottom: 1px solid RGB(var(--px_color_border)) !important;
}

.link {
  color: RGB(var(--px_color_link));
}

.small {
  font-size: 1.1rem;
}

.faded_1 {
  color: RGBA(var(--px_color_text_primary), var(--px_alpha_darker));
}

.faded_2 {
  color: RGBA(var(--px_color_text_primary), var(--px_alpha_medium));
}

.inline-block {
  display: inline-block;
}

.px-one-line-data {
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
}

.hide-for-sm-only {
  display: none !important;
}

.show-for-sm-only {
  display: inline-block !important;
}

.border-lg-top {
  border-top: initial;
}

.px-popup {
  max-width: 500px;
}

.px-underline {
  text-decoration: underline;
}

.px-import-dialog-min-width-500px .MuiPaper-root {
  min-width: 500px;
}

.vertical-top {
  vertical-align: top;
}

.vertical-middle {
  vertical-align: middle;
}

.text-faded {
  opacity: 0.5;
}

*.text-muted,
*.text-muted * {
  color: RGB(var(--px_color_text_muted));
}

.px-fontsize-12px {
  font-size: 12px;
}

.link {
  color: RGB(var(--px_color_link));
  display: inline-block;
}

.thin-line-height {
  line-height: 1rem;
}

.relative {
  position: relative;
}

.hover-bg:hover {
  background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
}

.hover-bg-light:hover {
  background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
  display: inline-block;
  height: auto;
}

.inline-block {
  display: inline-block;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.px-height-100vh {
  height: 100vh;
}

.px-height-95vh {
  height: 95vh;
}

.px-min-width-180px,
.MuiTextField-root.px-min-width-180px {
  min-width: 180px;
}

.px-margin-vertical-8px {
  margin: 8px 0;
}

.pointer-mouse {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.px-flex-column {
  display: flex;
  flex-direction: column;
}

.px-flex-row {
  display: flex;
  flex-direction: row;
}

.px-align-center {
  align-items: center;
}

.px-position-sticky {
  position: sticky;
  top: 0;
}

.px-text-description {
  color: RGB(var(--px_color_text_secondary));
}

.px-settings-text-description {
  color: RGBA(var(--px_color_text_secondary), var(--px_alpha_muted));
}

.full-width {
  width: 100% !important;
}

.deactivated-feedback {
  background: #000000;
  color: #ffffff;
  padding: 12px 16px;
}

.px-position-static {
  position: static;
}

.px-position-absolute {
  position: absolute;
}

.px-position-fixed {
  position: fixed;
}

.px-text-secondary {
  color: RGB(var(--px_color_text_on_secondary));
}

.px-link-btn {
  color: RGB(var(--px_color_link));
}

.px-display-none {
  display: none;
  height: 0;
  width: 0;
}

.inline-warning {
  color: RGB(var(--px_color_warning));

  &>* {
    margin: 2px;
  }
}

.px-width-75px {
  width: 75px;
}

.px-rotate-90deg {
  transform: rotate(90deg);
}

.px-display-flex {
  display: flex;
}

#compulsory-filter-menu {
  z-index: 3;

  div.ag-theme-alpine {
    &.stocklist-demand-analytics-popup {

      // overflow-y: scroll;
      // overflow-x: hidden;
      // height: 300px;
      // max-height: 300px;
      // padding: 0;
      .ag-row-odd {
        background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
      }

      .ag-floating-top {
        background-color: #ffffff;
      }

      .ag-floating-top-viewport {
        background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));

        .ag-row.ag-row-even.ag-row-pinned.ag-row-level-0.ag-row-position-absolute.ag-row-first {
          background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
        }
      }
    }

    .ag-floating-top-container [role='row'] .ag-cell {
      display: flex;
      align-items: center;
    }

    .ag-cell {
      padding: 0 !important;
      font-size: 14px;
      border: none;

      &[col-id='LABEL'] {
        width: 100px !important;

        .ag-cell-value {
          padding: 0 0 0 4px;
        }

        .ag-react-container {
          position: static;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          padding: 12px 0 0 4px;

          .MuiSvgIcon-root {
            position: absolute;
            height: 18px;
            width: 18px;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  .MuiList-root[role='menu'] {
    padding: 0 !important;

    .ag-theme-alpine {
      padding: 8px;

      .ag-row {
        border: 0 !important;
      }
    }
  }
}

.badge {
  display: inline-block;
  min-width: 2em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  vertical-align: top;

  &.alert {
    background-color: #cc4b37;
    color: #fefefe;
  }
}

.px-full-height-width-center-content {
  height: '100%' !important;
  width: '100%' !important;
  display: 'flex' !important;
  justify-content: 'center' !important;
  align-items: 'center' !important;
}

div[role='tooltip'] {
  background-color: RGB(var(--px_color_background));
  border-radius: 7px;
  border-radius: 5px;

  h2 {
    font-weight: bold;
    margin: 20px 0 0 0;
  }
}

#search-tooltip,
#search-tooltip * {
  background-color: RGB(var(--px_color_background));
  color: RGB(var(--px_color_text_secondary));
  transition: none;
}

#search-tooltip {
  white-space: pre;
  font-size: 1rem;
  width: 360px;

  .px-tooltip-heading {
    vertical-align: middle;
    font-size: 1.3rem;
    color: RGB(var(--px_color_text_secondary));

    svg {
      font-size: 26px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .px-tooltip-description {
    color: RGB(var(--px_color_text_primary));
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    overflow-wrap: break-word;

    span {
      font-size: 1rem;
      padding: 2px 0;
    }

    .px-tooltip-font-large {
      font-size: 1.2rem;
      overflow-wrap: break-word;
      padding: 8px 0;
    }
  }
}

div[role='tooltip'],
.px-box {
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.6);
}

div.px-popper-menu[role='tooltip'] {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 10;
}

div[role='tooltip'].px-popper-menu.px-accounts-menu {
  z-index: 1300;
}

.text-right-sm {
  text-align: right;
}

.px-margin-right {
  margin-right: 5px !important;
}

.px-big-icon {
  svg {
    width: auto;
    height: 3rem;
  }
}

.hide-for-sm-only-display-block {
  display: none !important;
}

.disabled {
  pointer-events: none;
}

.px-pointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.px-color-link {
  color: RGB(var(--px_color_link)) !important;
}

a {
  color: RGB(var(--px_color_link));
}

// >> mui theme styles

// change the color of snackbar

.MuiTabs-root {
  .MuiTab-root.MuiTab-textColorSecondary {
    color: RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));

    &.Mui-selected {
      color: RGB(var(--px_color_text_primary));
    }
  }
}

.MuiChip-root {
  padding: 8px !important;

  &.MuiChip-outlinedPrimary {
    color: RGB(var(--px_color_primary));
    border-color: RGB(var(--px_color_primary));

    .MuiSvgIcon-root {
      color: RGB(var(--px_color_primary));
    }
  }

  .MuiChip-label {
    font-size: 14px;
  }
}

.MuiDropzoneArea-root {

  &,
  .MuiDropzoneArea-icon {
    color: RGB(var(--px_color_primary));
    border-color: RGB(var(--px_color_primary));
  }

  border: 1px dashed !important;

  &.Mui-error {

    &,
    .MuiDropzoneArea-icon {
      color: RGB(var(--px_color_error));
      border-color: RGB(var(--px_color_error));
    }
  }

  .MuiDropzonePreviewList-image,
  .MuiDropzonePreviewList-removeButton {
    color: RGB(var(--px_color_primary));
    background-color: RGB(var(--px_color_background));
  }

  .MuiDropzonePreviewList-removeButton:hover {
    color: RGB(var(--px_color_text_on_primary));
    background-color: RGB(var(--px_color_primary));
  }
}

.MuiButton-root {
  .MuiButton-label {
    font-size: 14px;
    font-weight: 500;
  }

  &.MuiButton-textPrimary {
    color: RGB(var(--px_color_primary));
  }

  &.MuiButtonBase-root.Mui-disabled {
    color: RGB(var(--px_color_text_muted));
  }
}

.MuiSwitch-root {
  .MuiSwitch-thumb {
    color: RGB(var(--px_color_background));
  }

  .MuiSwitch-track {
    background-color: RGBA(var(--px_color_border));
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    .MuiSwitch-thumb {
      color: RGB(var(--px_color_primary));
    }

    &+.MuiSwitch-track {
      background-color: RGBA(var(--px_color_primary), 1);
    }
  }
}

div.MuiPopover-root {
  background-color: RGBA(var(--px_color_border), var(--px_alpha_light));
}

.MuiFormLabel-root {
  color: RGB(var(--px_color_text_secondary)) !important;

  &.Mui-error {
    color: RGB(var(--px_color_error_input)) !important;
  }
}

.MuiButtonBase-root,
.MuiButton-root {
  .MuiButton-label {
    font-size: 14px;
    font-weight: 500;
  }

  &.MuiButton-textPrimary {
    color: RGB(var(--px_color_primary));
  }

  &.MuiButton-outlined:not(.Mui-disabled),
  &.MuiButton-outlined:not(.Mui-disabled):hover {
    border-color: RGB(var(--px_color_primary));

    .MuiButton-label {
      color: RGB(var(--px_color_primary));
    }
  }

  &.MuiButton-containedPrimary,
  &.MuiButton-containedPrimary:hover {
    background-color: RGB(var(--px_color_primary));
  }

  &.MuiIconButton-root:not(.MuiPickersDay-dayDisabled, .MuiPickersDay-daySelected) {
    color: RGB(var(--px_color_primary));
  }

  &.MuiPickersDay-daySelected {
    color: RGB(var(--px_color_text_on_primary));
    background-color: RGB(var(--px_color_primary));
  }

  &.MuiIconButton-root:hover {
    color: RGB(var(--px_color_text_on_primary));
    background-color: RGBA(var(--px_color_primary), var(--px_alpha_darker));
  }

  &.MuiPickersDay-dayDisabled {
    color: RGB(var(--px_color_text_muted));
  }
}

.MuiPaper-root.MuiCard-root {
  background-color: RGB(var(--px_color_background));
  color: RGB(var(--px_color_text_primary));
  // &.MuiPaper-elevation1 {
  //   // box-shadow: 0px 2px 6px 4px RGBA(var(--px_color_border), 0.16),
  //   //   0px 1px 1px 4px RGBA(var(--px_color_border), 0.14),
  //   //   0px 1px 3px 2px RGBA(var(--px_color_border), 0.12);
  //   box-shadow: 0px 2px 1px -1px RGBA(var(--px_color_border), 1),
  //     0px 1px 1px 0px RGBA(var(--px_color_border), 0.94),
  //     0px 1px 1px 0px RGBA(var(--px_color_border), 0.92);
  // }
}

.MuiPickersCalendarHeader-switchHeader .MuiTypography-root {
  color: RGB(var(--px_color_text_secondary));
}

.MuiPickersCalendarHeader-daysHeader .MuiTypography-root {
  color: RGB(var(--px_color_text_secondary));
}

.MuiToolbar-root.MuiPickersToolbar-toolbar {
  color: RGB(var(--px_color_text_on_primary));
  background-color: RGB(var(--px_color_primary));
}

.MuiLinearProgress-root {
  &.MuiLinearProgress-colorPrimary {
    .MuiLinearProgress-dashedColorPrimary {
      background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
    }

    .MuiLinearProgress-bar.MuiLinearProgress-colorPrimary.MuiLinearProgress-bar2Buffer {
      background-color: RGBA(var(--px_color_primary), var(--px_alpha_muted));
    }

    .MuiLinearProgress-barColorPrimary {
      background-color: RGB(var(--px_color_primary));
    }
  }
}

.MuiInputBase-root {
  &:not(.Mui-disabled) {
    color: RGB(var(--px_color_text_primary));
  }

  &.Mui-disabled,
  &.Mui-disabled .MuiInputAdornment-root {
    color: RGB(var(--px_color_text_muted));
  }
}

.MuiTextField-root {
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: RGBA(var(--px_color_text_secondary), var(--px_alpha_darker));
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: RGB(var(--px_color_error_input));
    }

    &.Mui-focused,
    &.Mui-focused:focus,
    &.Mui-focused:focus-visible {
      border-color: RGB(var(--px_color_primary));
      outline: RGB(var(--px_color_primary));
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: RGB(var(--px_color_error_input));
  }
}

.MuiPaper-root.MuiDialog-paper {
  background-color: RGB(var(--px_color_background));
  color: RGB(var(--px_color_text_primary));
}

div.MuiBackdrop-root {
  background-color: RGBA(var(--px_color_border), var(--px_alpha_medium));
}

.MuiBadge-badge.MuiBadge-colorPrimary {
  background-color: RGB(var(--px_color_primary));
}

.MuiFormLabel-root {
  color: RGB(var(--px_color_text_secondary)) !important;

  &.Mui-error {
    color: RGB(var(--px_color_error_input)) !important;
  }
}

.MuiChip-root.px-filter-on {
  background-color: RGB(var(--px_color_primary));
  color: RGB(var(--px_color_text_on_primary));

  &:hover {
    background-color: RGB(var(--px_color_primary)) !important;
  }

  .MuiSvgIcon-root {
    color: RGB(var(--px_color_text_on_primary));
  }
}

.MuiTabs-root .MuiTabs-indicator {
  background-color: RGB(var(--px_color_primary));
}

.MuiFab-root.MuiFab-primary {
  color: #ffffff;
  background-color: RGB(var(--px_color_primary));

  &:hover {
    background-color: RGB(var(--px_color_primary));
  }
}

.MuiList-root {
  background-color: RGB(var(--px_color_surface));
  color: RGB(var(--px_color_text_secondary));
}

.MuiRadio-root {
  &.MuiRadio-colorPrimary.Mui-checked {
    color: RGB(var(--px_color_primary));
  }
}

.MuiCheckbox-root {
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: RGB(var(--px_color_primary));
  }

  &.MuiCheckbox-colorPrimary.Mui-disabled,
  &.Mui-disabled input[type='checkbox'] {
    color: RGBA(var(--px_color_border), var(--px_alpha_muted));
  }
}

.MuiSvgIcon-root {
  &.MuiSvgIcon-colorPrimary {
    color: RGB(var(--px_color_primary));
  }
}

.MuiBadge-badge.MuiBadge-colorPrimary {
  background-color: RGB(var(--px_color_primary));
}

.MuiButton-root.px-link-btn,
.MuiButton-root.px-link-btn span {
  color: RGB(var(--px_color_primary)) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiButton-root.px-link-btn:hover,
.MuiButton-root.px-link-btn:hover {
  color: RGB(var(--px_color_primary)) !important;
  background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter)) !important;
}

.MuiTable-root .MuiTableCell-body {
  color: RGB(var(--px_color_text_primary));
}

.MuiSnackbar-root {
  .MuiAlert-root {
    color: RGB(var(--px_color_text_on_snackbar));

    &.MuiAlert-filledInfo {
      background-color: RGB(var(--px_color_info));
    }

    &.MuiAlert-filledError {
      background-color: RGB(var(--px_color_error));
    }

    &.MuiAlert-filledSuccess {
      background-color: RGB(var(--px_color_success));
    }

    &.MuiAlert-filledWarning {
      background-color: RGB(var(--px_color_warning));
    }

    .MuiAlert-icon .MuiSvgIcon-root,
    .MuiAlert-message,
    .MuiAlert-action .MuiSvgIcon-root,
    .MuiButton-root {
      color: RGB(var(--px_color_text_on_snackbar));
    }
  }

  .MuiDropzoneSnackbar-successAlert {
    background-color: RGB(var(--px_color_success));
  }

  .MuiDropzoneSnackbar-infoAlert {
    background-color: RGB(var(--px_color_info));
  }

  .MuiSvgIcon-root.MuiDropzoneSnackbar-icon {
    color: RGB(var(--px_color_text_on_snackbar));
  }
}

// ag grid styles
.ag-theme-alpine {

  .ag-header-row:not(:first-child) .ag-header-cell,
  .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
    border-top: none;
  }

  .ag-header-viewport .ag-header-container .ag-header-row {

    .ag-header-group-cell,
    .ag-header-group-cell.ag-header-group-cell-with-group,
    .ag-header-cell {
      border-top: none !important;
      border-bottom: none !important;
    }
  }

  .ag-body-viewport {
    scroll-behavior: smooth;
  }

  .ag-header-cell-text,
  .ag-header-group-text {
    font-size: 12px;
    font-weight: 400;
    color: RGB(var(--px_color_text_secondary));
  }

  .ag-header-icon svg {
    // height: 12px;
    fill: RGB(var(--px_color_text_secondary));
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-floating-top,
  .ag-floating-top-viewport,
  .ag-header {
    z-index: 4;
    background-color: RGB(var(--px_color_surface));
  }

  .ag-body-horizontal-scroll {
    position: fixed;
    bottom: 54px;
  }

  .ag-body-viewport {
    padding-bottom: 16px;
  }

  .ag-floating-top-container .ag-row,
  .ag-pinned-left-floating-top .ag-row {
    background-color: RGBA(var(--px_color_info), var(--px_alpha_light));
  }

  .ag-floating-top-container [role='row'] .ag-cell,
  .ag-row-pinned .ag-cell,
  .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-root-wrapper {
    border: none;
  }

  .px-detail-grid {
    .ag-header-cell .ag-header-cell-label {
      margin-left: 0px;
    }
  }

  .ag-header-cell .ag-header-cell-label {
    margin-left: -10px;
  }

  [ref='eResize'],
  [ref='agResize'] {
    display: none;
  }

  #popper[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

  .ag-cell-wrap-text {
    line-height: 20px;
    padding: 8px;
  }

  .ag-floating-top,
  .ag-row,
  .ag-header-row .ag-header-cell,
  .ag-header-group-cell-with-group,
  .ag-header-row-column {
    border-bottom: 0px;
    border-top: 0px;
  }

  .px-border-bottom-1px {
    border-bottom: 1px solid RGB(var(--px_color_border)) !important;
  }

  .ag-cell,
  .text-primary {
    color: RGB(var(--px_color_text_primary));
  }

  .ag-cell {
    padding: 0;
  }

  .ag-floating-top.ag-selectable {
    background-color: RGBA(var(--px_color_info), var(--px_alpha_light));
  }

  .ag-cell-inline-editing {
    box-shadow: none;
    background-color: transparent;
    border-right-width: 0;
  }

  // to give a better visibility on the input with black text (doesn't affect other styles)
  .MuiFormControl-root.MuiTextField-root {
    background-color: RGB(var(--px_color_surface));
  }

  .ag-group-child-count {
    font-weight: 700 !important;
  }

  .ag-floating-top.ag-selectable {
    overflow-y: hidden !important;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-floating-top,
  .ag-pinned-left-header {
    box-shadow: RGB(var(--px_color_border)) -10px -22px 23px 6px;
    z-index: 4;
  }

  div.ag-checkbox-input-wrapper {
    color: RGB(var(--px_color_primary));
    background-color: RGB(var(--px_color_primary));
    border-color: RGB(var(--px_color_primary));
    border-width: 4px;
  }

  div.ag-checkbox-input-wrapper::after {
    background-color: RGB(var(--px_color_background));
  }

  div.ag-checkbox-input-wrapper.ag-checked::after {
    color: RGB(var(--px_color_primary));
  }

  .ag-overlay {
    z-index: 5;
    background-color: RGBA(var(--px_color_background), var(--px_alpha_muted));
  }

  @media screen and (min-width: 1024px) {
    .ag-body-horizontal-scroll {
      position: fixed;
      bottom: 0;
      z-index: 100;
      padding-right: 26px;
    }

    .ag-body-horizontal-scroll-viewport {
      overflow-x: scroll;
      margin-right: 24px;
    }
  }
}

.phonex_theme_variant.px-settings-row-background {
  background-color: #f8f8f8;
}

.max-width-1020px {
  max-width: 1020px;
}

.px-settings-description {
  max-width: 800px;
  font-size: 16px;
  padding-bottom: 8px;
  line-height: 24px;
}

.px-settings-title {
  font-size: 20px;
  font-weight: 500;
}

.px-settings-grid {

  .ag-header-cell-text,
  .ag-header-group-text {
    font-size: 12px;
    font-weight: 400;
    color: RGB(var(--px_color_text_secondary));
  }

  .ag-cell {
    white-space: normal;
  }


  .ag-cell[aria-colindex="1"],
  .ag-header-cell[aria-colindex="1"] {
    padding: 0 0 0 8px;
  }
}

div.px-color-text-primary * {
  color: RGB(var(--px_color_text_primary));
}

#pxOrderGrid .ag-body-horizontal-scroll {
  position: static;
  padding-right: 0;
}

#pxOrderGrid .ag-body-horizontal-scroll-viewport {
  margin-right: 0;
}

// recent days header formatting
.px-recent-days-header-date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: RGB(var(--px_color_primary));

  // date styling
  .px-recent-days-range-picker {
    width: 6ch !important;

    .MuiInput-underline:before {
      border-bottom: none;
    }

    .MuiInputBase-input {
      color: RGB(var(--px_color_primary));
    }
  }
}

.px-order-add-filter {
  max-height: 300px;
  overflow: auto;
  margin-top: 8px;
}

.px-stats-show-icon-container {
  line-height: 20px;
}

.min-width-300px {
  min-width: 300px;
}

.px-no-warehouse-found {
  color: #000000;
}

.px-grid-chips {
  padding: 0.4rem 0.7rem;
  font-size: 12px;
  border-radius: 16px;
  color: RGB(var(--px_color_text_on_badge));
  line-height: 40px !important;
}
.px-grid-chips:hover {
  cursor: pointer;
}
.px-background-primary {
  background-color: RGB(var(--px_color_primary));
}

.px-background-secondary {
  background-color: RGB(var(--px_color_secondary));
}

.px-background-warning {
  background-color: RGB(var(--px_color_warning));
}

.px-background-error {
  background-color: RGB(var(--px_color_error));
}

.px-background-badge_1 {
  background-color: RGB(var(--px_color_badge_1));
}

.px-background-badge_1b {
  background-color: RGB(var(--px_color_badge_1b));
}

.px-background-badge_2 {
  background-color: RGB(var(--px_color_badge_2));
}

.px-background-badge_2b {
  background-color: RGB(var(--px_color_badge_2b));
}

.px-background-badge_3 {
  background-color: RGB(var(--px_color_badge_3));
}

.px-background-badge_3b {
  background-color: RGB(var(--px_color_badge_3b));
}

.px-background-badge_4 {
  background-color: RGB(var(--px_color_badge_4));
}

.px-background-badge_5 {
  background-color: RGB(var(--px_color_badge_5));
}

.px-background-badge_6 {
  background-color: RGB(var(--px_color_badge_6));
}

.px-background-rejected{
  background-color: #BE5700;
}

@media screen and (min-width: 640px) {
  .hide-for-medium-large-only {
    display: none !important;
  }

  .hide-for-sm-only-display-block {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .px-box {
    border: 1px solid RGB(var(--px_color_border));
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .hide-for-sm-only {
    display: inline-block !important;
  }

  .show-for-sm-only {
    display: none !important;
  }

  .border-lg-top {
    border-top: 1px solid RGB(var(--px_color_border));
  }

  .text-right-sm {
    text-align: initial;
  }

  .px-margin-right {
    margin-right: 30px !important;
  }
}

.px-home-padding {
  padding-top: 52px;
}

.px-main-padding {
  padding-top: 22px;
  padding-bottom: 0;
}

.px-hide-640px {
  display: none;
}

@media (min-width: 640px) {
  .px-hide-640px {
    display: flex;
  }

  .px-show-640px {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .px-box {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .hide-for-sm-only {
    display: inline-block !important;
  }

  .show-for-sm-only {
    display: none !important;
  }

  .show-for-sm-medium-only {
    display: none !important;
  }

  .show-for-large-only {
    display: block !important;
  }

  .border-lg-top {
    border-top: 1px solid RGB(var(--px_color_border));
  }

  .text-right-sm {
    text-align: initial;
  }

  .px-margin-right {
    margin-right: 30px !important;
  }
}

@media (max-width: 400px) {
  .margin-formControl.MuiFormControlLabel-root {
    .px-payment-method-instruction-container {
      margin-left: -32px;
      margin-right: -16px;

      * {
        font-size: 96%
      }
    }
  }

  .font-small-h2 {
    font-size: 1.5rem;
  }
}

.px-fontsize-16px {
    font-size: 16px;
}

.px-bold {
  font-weight: bold;
}
