:root {
  --px_color_primary: 82, 163, 255;
  --px_color_secondary: 255, 244, 146;
  // link color
  --px_color_link: 80, 161, 255;
  --px_color_link_active: 80, 161, 255;
  --px_color_link_visited: 80, 161, 255;
  --px_color_link_hover: 80, 161, 255;
  // text color
  --px_color_text_primary: 33, 33, 33;
  --px_color_text_secondary: 33, 33, 33;
  --px_color_text_muted: 33, 33, 33;
  --px_color_text_variant: 33, 33, 33;
  --px_color_text_on_primary: 255, 255, 255;
  --px_color_text_on_secondary: 33, 33, 33;
  --px_color_text_on_snackbar: 255, 255, 255;
  --px_color_text_on_badge: 255, 255, 255;
  // background colors
  --px_color_background: 255, 255, 255;
  --px_color_surface: 255, 255, 255;
  // feedback colors
  --px_color_error: 211, 47, 47;
  --px_color_error_input: 213, 8, 8;
  --px_color_error_text: 213, 8, 8;
  --px_color_warning: 245, 124, 0;
  --px_color_success: 76, 175, 80;
  --px_color_info: 32, 32, 32;
  // border colors
  --px_color_border: 211, 211, 211;
  --px_color_border_light: 229, 229, 229;
  // badge colors
  --px_color_badge_1: 219, 162, 0;
  --px_color_badge_1b: 219, 162, 0;
  --px_color_badge_2: 90, 139, 48;
  --px_color_badge_2b: 90, 139, 48;
  --px_color_badge_3: 47, 80, 172;
  --px_color_badge_3b: 47, 80, 172;
  --px_color_badge_4: 138, 58, 229;
  --px_color_badge_5: 210, 30, 169;
  --px_color_badge_6: 129, 108, 108;
  // font
  --px_font_title: 'helvetica';
  --px_font_body: 'Roboto';
  // logo dimensions
  --px_topLogoWidth: 200px;
  --px_topLogoHeight: 25px;
  // alpha values
  --px_alpha_lighter: 0.03;
  --px_alpha_light: 0.1;
  --px_alpha_muted: 0.33;
  --px_alpha_medium: 0.4;
  --px_alpha_darker: 0.63;

  .phonex_theme_variant {
    --px_color_primary: 82, 163, 255;
    --px_color_secondary: 255, 244, 146;
    // link color
    --px_color_link: 80, 161, 255;
    --px_color_link_active: 80, 161, 255;
    --px_color_link_visited: 80, 161, 255;
    --px_color_link_hover: 80, 161, 255;
    // text color
    --px_color_text_on_primary: 255, 255, 255;
    --px_color_text_primary: 33, 33, 33;
    --px_color_text_secondary: 33, 33, 33;
    --px_color_text_muted: 33, 33, 33;
    --px_color_text_on_secondary: 33, 33, 33;
    --px_color_text_on_snackbar: 255, 255, 255;
    --px_color_text_on_badge: 255, 255, 255;
    // background colors
    --px_color_background: 255, 255, 255;
    --px_color_surface: 255, 255, 255;
    // feedback colors
    --px_color_error: 211, 47, 47;
    --px_color_error_input: 213, 8, 8;
    --px_color_error_text: 213, 8, 8;
    --px_color_warning: 245, 124, 0;
    --px_color_success: 76, 175, 80;
    --px_color_info: 32, 32, 32;
    // border colors
    --px_color_border: 211, 211, 211;
    --px_color_border_light: 229, 229, 229;
    // badge colors
    --px_color_badge_1: 219, 162, 0;
    --px_color_badge_1b: 219, 162, 0;
    --px_color_badge_2: 90, 139, 48;
    --px_color_badge_2b: 90, 139, 48;
    --px_color_badge_3: 47, 80, 172;
    --px_color_badge_3b: 47, 80, 172;
    --px_color_badge_4: 138, 58, 229;
    --px_color_badge_5: 210, 30, 169;
    --px_color_badge_6: 129, 108, 108;
    // font
    --px_font_title: 'helvetica';
    --px_font_body: 'Roboto';
    // logo dimensions
    --px_topLogoWidth: 200px;
    --px_topLogoHeight: 25px;
    // alpha values
    --px_alpha_lighter: 0.03;
    --px_alpha_light: 0.1;
    --px_alpha_muted: 0.33;
    --px_alpha_medium: 0.4;
    --px_alpha_darker: 0.63;
  }

  // AMPLIFY
  --amplify-primary-color: RGB(var(--px_color_primary));
  --amplify-primary-tint: RGB(var(--px_color_text_primary));
  --amplify-primary-shade: RGB(var(--px_color_text_primary));
  --amplify-background-color: RGB(var(--px_color_background));
  --amplify-secondary-color: RGB(var(--px_color_text_primary));
  --amplify-font-family: RGB(var(--px_font_title));

  // AG-GRID
  --ag-odd-row-background-color: RGBA(var(--px_color_primary), var(--px_alpha_lighter));
}
