@keyframes fadeInAnimation {
  from {
    opacity: 0;
    display: none;
  }
  to {
    opacity: 1;
    display: block;
  }
}

@keyframes fadeOutAnimation {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
.fade-in{
  animation-name: fadeInAnimation;
  animation-duration: 0.4s;
}

.fade-out{
  animation-name: fadeOutAnimation;
  animation-duration: 0.4s;
}