.px-ConfigTenant {
    .MuiTabPanel-root {
        padding: 40px;
    }
    .px-config-wait {
        margin: 20px 0;
        max-width: 400px;
    }
    .link {
        cursor: pointer;
    }
}
