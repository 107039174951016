.px-update-clientForms {
  .form-fields {
    margin-bottom: 3rem;
    .fields {
      width: 45%;
      min-width: 250px;
      @media only screen and (max-width: 786px) {
        width: 100%;
      }
    }
    .file-field:hover {
      .MuiOutlinedInput-input, .MuiInputBase-root {
        cursor: default;
      }
    }
    .instruction {
      background-color: RGBA(var(--px_color_primary), 0.05);
      border-radius: 10px;
      margin-left: 2rem;
      font-size: 12px;
      padding: 10px;
      display: flex;
      align-items: center;
      width: 20%;
      min-width: 250px;
      @media only screen and (max-width: 786px) {
        margin-left: 0;
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
  .success {
    color: RGB(var(--px_color_success));
    transform: scale(1.6);
  }
  .instruction {
    background-color: RGBA(var(--px_color_primary), 0.05);
    border-radius: 10px;
    margin-left: 2rem;

    padding: 10px;
    display: flex;
    width: 70%;
    min-width: 250px;
    .px-fontsize-24px {
      font-size: 24px;
    }
    .pointer:hover {
      cursor: pointer;
    }
  }
}
