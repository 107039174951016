@mixin generate_padding_margins($spaceamounts, $sides) {
  @each $space in $spaceamounts {
    @each $side in $sides {
      .m-#{str-slice($side, 0, 1)}-px-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p-#{str-slice($side, 0, 1)}-px-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}
