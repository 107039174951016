.client-payment-method {
  .payment-method-select {
    min-width: 250px;
  }
}
.wired-payment,
.card-payment {
  .details {
    width: 40%;
    margin: 1.2rem 0 1.2rem 0;
    .detail {
      padding: 0.8rem 0 0.8rem 0;
    }
  }
}
.card-payment {
  .card-header {
    display: flex;
    gap: 0.5rem;
    .title {
      margin: 0;
    }
    .cancel {
      margin-left: auto;
    }
  }
  button {
    padding: 0 0.5rem;
  }
}
.contract-terms-section {
  position: relative;
  .doc {
    display: flex;
    align-items: center;
    .icon {
      height: 15px;
      width: 15px;
    }
  }
  .tier {
    gap: 60px;
    .item {
      min-width: 100px;
    }
    .value {
      margin-top: 8px;
    }
  }
  .button-group {
    position: absolute;
    right: 0;
  }
}
