.px-v-dashboard {
  position: relative;
  padding-bottom: 80px;
  // min-height: 100vh;
}

a.px-error-logout {
  color: #fff !important;
  text-decoration: underline;
}

.px-import-popup {
  z-index: 9999 !important;
  .MuiDialogActions-root {
    padding: 10px 20px 20px 0 !important;
    button:first-of-type {
      margin-right: 20px !important;
    }
  }
  #alert-dialog-description {
    font-size: 1rem;
  }
}

.MuiFormControl-root.px-popup-menu {
  min-width: 200px;
}

.pointer-mouse {
  cursor: pointer;
}

.MuiDropzoneArea-text {
  margin: 24px !important;
}

amplify-authenticator {
  --container-align: top !important;
}

// .ag-theme-alpine .ag-header-cell,
// .ag-theme-alpine .ag-header-group-cell {
//   padding-left: 10px;
//   padding-right: 5px;
// }

.ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

// .ag-theme-alpine .ag-group-expanded .ag-icon {
//   color: RGB(var(--px_color_primary));
// }
