.px-c-Login {
  white-space: nowrap;
  table,
  table tr {
    width: 100%;
  }
  table tr td {
    width: auto;
  }
  table tr td:last-of-type {
    padding-left: 10px;
  }
  .px-forgot-text {
    text-align: center;
    margin-bottom: 30px;
    color: RGB(var(--px_color_text_primary), var(--px_alpha_darker));
  }
  h3 {
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
    color: RGB(var(--px_color_text_on_secondary));
  }
}

div#new-paswd-policy[role='tooltip'] {
  z-index: 1010;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  padding-left: 0 !important;
  background-color: #fff;
  padding-bottom: 15px;
  width: auto;
  font-size: revert;
  .dialog-title,
  .dialog-content {
    background-color: #fff;
    .px-text-description {
      color: RGB(var(--px_color_text_secondary)) !important;
    }
  }
  .MuiTypography-h6 {
    font-size: 1.25rem;
  }
  .MuiSvgIcon-root {
    font-size: 1.75rem;
  }
  .MuiTypography-body1 {
    font-size: 1.2rem;
  }
  span.arrow-popover {
    background: transparent;
  }
  .box-shadow-none {
    box-shadow: none !important;
  }
}

span.arrow-popover,
span.arrow-popover::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

span.arrow-popover {
  left: -11px;
  margin-top: -11px;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 11px;
  border-left-width: 0;
  background: transparent;
  &::after {
    box-sizing: border-box;
    bottom: -10px;
    left: 1px;
    border-right-color: #fff;
    border-width: 10px;
    border-left-width: 0;
    content: ' ';
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    height: 14.14px;
    width: 14.14px;
    bottom: -8px;
    left: 4px;
    background: transparent;
    transform: rotate(45deg);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
  }
}

.login-logo {
  max-height: 100px;
  width: auto;
}

amplify-authenticator {
  --container-height: calc(100vh - 45px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.px-reset-password {
  max-width: 400px;
  padding: 35px 40px;
  margin-top: 30px;
  h3 {
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
    color: #000;
  }
}

.px-reset-pswd-text {
  font-size: 0.9rem;
}

@media only screen and (max-width: 786px) {
  .px-c-Login {
    table tr td:first-of-type {
      height: 24px;
    }
    table tr td {
      display: block;
      width: 100%;
      height: 44px;
    }
    table tr td:last-of-type {
      padding-left: 0;
      margin-bottom: 20px;
    }
  }
}
