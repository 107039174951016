.px-registration-form {
  margin-left: 62px;
  margin-right: 10%;
  margin-bottom: 64px;

  .px-details {
    background-color: rgba(var(--px_color_primary), 0.05);
    max-width: 1100px;
    border-radius: 10px;
    .client-not-found {
      padding: 1rem 1rem 2rem;
    }
    .found-client {
      padding: 2rem 2rem 1rem;
    }
  }
  .px-list-space {
    li {
      margin-bottom: 1rem;
    }
  }
  .px-registration-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .px-fields {
      display: flex;
      flex-direction: column;
      .MuiTextField-root {
        min-width: 240px;
        max-width: 480px;
      }
    }
  }
  .section-center {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.2rem;
    .MuiTextField-root {
      width: 480px;
    }
  }
  .font-24px {
    font-size: 1.5rem;
  }
  .line-height-1 {
    line-height: 1;
  }
  .gap-5px {
    gap: 5px;
  }
  .show-icon-text {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .error-icon {
    color: RGB(var(--px_color_badge_1));
  }
  .mail-icon {
    color: RGB(var(--px_color_badge_1));
  }
  .success {
    color: RGB(var(--px_color_badge_2));
  }
  .px-text-bold {
    font-weight: bold;
  }
  .px-submit-btn {
    width: max-content;
    margin-top: 2rem;
  }
  .px-submit-btn.Mui-disabled {
    background: RGB(var(--px_color_primary), 0.2);
    color: RGB(var(--px_color_text_on_primary)) !important;
  }
}

.resend-email {
  cursor: pointer;
  padding: 10px;
}
@media (max-width: 530px) {
  .section-center {
    flex-direction: column;
    align-items: start !important;
    .MuiTextField-root {
      width: 240px !important;
    }
  }
}
