.billing-period {
  .details {
    width: 40%;
    margin: 1.2rem 0 1.2rem 0;
    .detail {
      padding: 0.8rem 0 0.8rem 0;
    }
  }
  .details-xl {
    width: 60%;
    margin: 1rem 0 1rem 0;
    .detail {
      padding: 0.8rem 0 0.8rem 0;
    }
  }
}
.align-right {
  text-align: right;
}
.go-back {
  margin-left: -20px !important;
}
